/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Styles
import './index.scss';
import PrivacyPolicyContent from './PrivacyPolicyContent';
import TermsAndConditionsContent from './TermsAndConditions';
/* -------------------------------------------------------------------------- */
/*                               Implementation                               */
/* -------------------------------------------------------------------------- */
function PrivacyPolicy() {
  /* ---------------------------------- HOOKS --------------------------------- */

  // Localization
  const { t } = useTranslation();

  // states
  const [activeTab, setActiveTab] = useState('privacy-policy');
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Container className="privacy-policy-container" fluid>
      <Row className="tabs-container">
        <Col
          className={`tab ${
            activeTab === 'privacy-policy' ? 'active-tab' : ''
          }`}
          sm={2}
          onClick={() => {
            setActiveTab('privacy-policy');
          }}
        >
          {t('tabs.privacyPolicy')}
        </Col>
        <Col
          className={`tab ${
            activeTab === 'terms-and-conditions' ? 'active-tab' : ''
          }`}
          sm={3}
          onClick={() => {
            setActiveTab('terms-and-conditions');
          }}
        >
          {t('tabs.termsAndConditions')}
        </Col>
      </Row>
      {activeTab === 'privacy-policy' && <PrivacyPolicyContent />}
      {activeTab === 'terms-and-conditions' && <TermsAndConditionsContent />}
    </Container>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["PrivacyPolicy", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PrivacyPolicy;
